<template>
  <v-container>
    <v-row>
      <v-col cols="12" :loading="loading">
        <v-row align="center" justify="center" style="height: 80vh">
          <v-card
            v-for="(user,index) in users"
            :key="user.attendee_id"
            class="ma-3 pa-6 text-center"
            min-width="30%"
          >
            <v-autocomplete
              class="pt-5"
              outlined
              dense
              :label="'Helpdesk User '+(index+1)"
              :items="attendees"
              :data-index="index"
              v-model="helpdesk_users[index+1]"
              item-text="full_name"
              item-value="attendee_id"
              :error-messages="form_error['helpdesk_'+(index+1)]"
              @change="updateHelpdesk(user.helpdesk_id,helpdesk_users[index+1])"
            ></v-autocomplete>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      users: [],
      helpdesk_users: {
        help_1: 1,
        help_2: 2,
      },
      attendees: [],
      form_error: {},
      dialog: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getUserList() {
      let _self = this;
      this.$axios.get(`admin/helpdesk/list`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            let data = response.data.data;
            _self.users = response_data.data;
            for (let key in data) {
              let helpdesk_id = data[key].helpdesk_id;
              _self.helpdesk_users[helpdesk_id] = data[key].attendee_id;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getAttemdeeList() {
      let _self = this;
      this.$axios.get("/admin/attendee/list?pagination=false&sponsor_type=11")
        .then((response) => {
          _self.attendees = response.data.data;
        })
        .catch(function () {});
    },
    updateHelpdesk(helpdesk_id, attendee_id) {
      console.log(helpdesk_id, attendee_id);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post(`admin/helpdesk/update`, {
        helpdesk_id: helpdesk_id,
        attendee_id: attendee_id,
      })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getAttemdeeList();
    this.getUserList();
  },
};
</script>